<template>
    <base-layout>
        <template v-slot:sidebar>
            <app-sidebar-details title="Service Provider Details" @close-sidebar="closeSidebar">
                <sidebar-details :uuid="UUID" @close-sidebar="closeSidebar"></sidebar-details>
            </app-sidebar-details>
        </template>

        <div class="page-matrixx-sp-groups container">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Devices</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-8 col-tab-12">
                    <devices-table 
                        :current-page="currentDevicePage"
                        :devices-uuid="UUID"
                        @row-click="showDetails"
                        @row-click-mobile="openDetails"
                    />
                </div>
                <div class="col-4 col-tab-12">
                    <app-adding-banner 
                        header="Add Devices" 
                        description="Easily add new devices within Matrixx"
                        :route="{name: 'matrixx-add-device'}"
                        action-title="Add"
                        image="service"
                    />
                </div>
            </div>
        </div>

        <app-loader v-if="loading"></app-loader>
    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'

import appSidebarDetails from '@/components/app-sidebar-details'
import sidebarDetails from '@/views/matrixx/sp-group-details'

import appAddingBanner from '@/components/app-adding-banner'

import appLoader from '@/components/app-loader'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'
import devicesTable from './devices-table'

export default {
    props: {
        filterUuid: { type: String, default: null }
    },
    components: {
        baseLayout,

        appSidebarDetails,
        sidebarDetails,
        devicesTable,

        appAddingBanner,

        appLoader,
        appTable,
        appPagination,
    },

    data() {
        return {
            UUID: '',
            loading: false,
            currentDevicePage: 1
        }
    },

    methods: {
        init(to, from) {
            this.currentDevicePage = to.params.page
        },

        /**
         * Service Details
         */
        openDetails(row) {
            this.$router.push({ name: 'matrixx-device-details', params: { UUID: row.UUID } })
        },

        showDetails(row) {
            if (this.UUID == row.UUID) {
                this.closeSidebar()
            } else {
                this.UUID = row.UUID
                this.openSidebar()
            }
        },

        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.UUID = null
            this.$emit('close-sidebar')
        },
    },

    computed: {
        ...mapGetters([
            'current_sp',
            'current_spid',

            'is_super_admin',
            'current_sp_roles',
            'available_service_providers',
        ])
    },
    
    beforeRouteEnter(to, from, next) {
        next(route => { route.init( to, from ) })
    },

    beforeRouteLeave(to, from, next) {
        this.closeSidebar()
        next()
    },
}
</script>

<style lang="scss">
.page-matrixx-sp-groups {
    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .devices-table {
        @include table-cols-width((250px, 120px, 200px, 120px, 85px), true);
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-matrixx-sp-groups {
        .devices-table {
            margin-bottom: 30px;

            @include table-cols-width((150px, 100px, 150px, 100px, 85px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-matrixx-sp-groups {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .devices-table {
            margin-bottom: 15px;

            @include table-cols-width-mobile((85px, 190px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }
    }
}
</style>